import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-588adc1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w3-modal modal-box" }
const _hoisted_2 = { class: "w3-modal-content w3-card-2 modal-box-content" }
const _hoisted_3 = { class: "w3-teal modal-box-header" }
const _hoisted_4 = { class: "w3-container w3-padding w3-light-grey modal-box-content-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_Transition, {
      "enter-active-class": "w3-animate-opacity",
      "leave-active-class": "animate__animated animate__fadeOut"
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_1, [
          _createVNode(_Transition, {
            "enter-active-class": "animate__animated animate__fadeInDown",
            "leave-active-class": "animate__animated animate__fadeOutUp"
          }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", _hoisted_2, [
                _createElementVNode("header", _hoisted_3, [
                  _createElementVNode("i", {
                    class: "x-icon-topright x-hoverable material-icons modal-close",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                  }, "close")
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                ])
              ], 512), [
                [_vShow, _ctx.modalBoxIsVisible]
              ])
            ]),
            _: 3
          })
        ], 512), [
          [_vShow, _ctx.modalBoxIsVisible]
        ])
      ]),
      _: 3
    })
  ]))
}