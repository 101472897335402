export interface IAuthState {
    user: User
}

export const createAuthState = function (): IAuthState {
    return {
        user: null
    }
}

export enum Language {
    En = 'en',
    EnGb = 'en-gb',
    EnUs = 'en-us',
    Es = 'es',
    De = 'de',
    Undefined = '',
}

export interface IUserData {
    id: number;
    username: string;
    isSuperuser: boolean;
    language: Language;
    loginsCount: number;
    allWordsCount: number;
    fullWordsCount: number;
}

export class User {
    readonly id: number;
    readonly username: string;
    readonly isSuperuser: boolean;
    readonly language: Language;
    readonly loginsCount: number;
    public allWordsCount: number;
    public fullWordsCount: number;

    constructor(
        id: number,
        username: string,
        isSuperuser: boolean,
        language: Language,
        loginsCount: number,
        allWordsCount: number,
        fullWordsCount: number
    ) {
        this.id = id;
        this.username = username;
        this.isSuperuser = isSuperuser;
        this.language = language;
        this.loginsCount = loginsCount;
        this.allWordsCount = allWordsCount;
        this.fullWordsCount = fullWordsCount;
    }
}


export interface ILoginData {
    username: string,
    password: string,
}