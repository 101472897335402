
import { defineComponent } from "vue";
import RoundButton from "./RoundButton.vue";

export default defineComponent({
  components: {
    RoundButton,
  },
  props: {
    borderColorClass: {
      type: String,
      required: false,
      default: "",
    },
    textUppercased: {
      type: Boolean,
      required: false,
      default: true,
    },
    icon: {
      type: String,
      required: false,
      default: "keyboard_arrow_down",
    },
    isLarger: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props, context) {
    const myClick = (event) => {
      context.emit("myClick", event);
    };

    return {
      myClick,
    };
  },
});
