
import { computed, defineComponent } from "vue";
import { hideModalSimple, getModalsState } from "@/store/actions/modals";
import { getModalColorClass, IModal } from "@/store/definitions/modals";

export default defineComponent({
  setup() {
    const modal = computed<IModal>(() => {
      return getModalsState().modalSimple;
    });
    const colorClass = computed<string>(() =>
      getModalColorClass(modal.value.type)
    );

    return {
      hideModalSimple,
      modal,
      colorClass,
    };
  },
});
