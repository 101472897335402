
import { getCurrentWord, getRepeatWords, setRepeatWords as setRepeatWordsAction } from "@/store/actions/repeat";
import { ModalType } from "@/store/definitions/modals";
import { IListing, IWord, IWordOutput } from "@/store/definitions/repeat";
import { computed, reactive } from "vue";
import { copyObject, shuffleArray, whiteToSpace } from "../common/utils";
import { User } from "@/store/definitions/auth";
import { getUser } from "@/store/actions/auth";

export const createWordId = function (wordString: string, isEnglishLanguage: boolean = true): string {
    let wordId = wordString
        .replace(/[.'"-\\/[](){}`~!?@#\$%\^&*_+-=|:<>]/gi, "");
    if (isEnglishLanguage) {
        wordId = wordId
            .replace(/someone/gi, "")
            .replace(/somebody/gi, "")
            .replace(/something/gi, "");
    }
    wordId = whiteToSpace(wordId)
        .toLowerCase();

    return wordId;
}

export const getOppositeLanguage = function (language: 'polish' | 'english'): 'polish' | 'english' {
    if (language === "polish") {
        return "english";
    }

    if (language === "english") {
        return "polish";
    }

    throw "Invalid language passed!";
}

export const setRepeatCurrentWords = function (): void {
    repeatState.currentWords = shuffleArray(repeatWords.value);
}

export const copyWord = function (word: IWord): IWord {
    return copyObject(word) as IWord;
}

export const mapIWordToOutput = function (word: IWord): IWordOutput {
    return {
        polish: word.polish,
        english: word.english,
        sentence: word.sentence,
        listingIds: word.listingIds,
        lexicalCategory: word.lexicalCategory,
        rating: word.rating
    }
}

export const copyListing = function (listing: IListing): IListing {
    return copyObject(listing) as IListing;
}

export const filterWordsByRating = function (rating: '1' | '2' | '3', words: Array<IWord>) {
    return words.filter(
        (word: IWord) => word.rating === rating
    );
}

export const filterWordsByIds = function (ids: Array<string>, words: Array<IWord>) {
    return words.filter(
        (word: IWord) => ids.indexOf(word.id) !== -1
    );
}

const filterWordsByListing = function (listingId: string, words: Array<IWord>) {
    return words.filter(
        (word: IWord) => word.listingIds.indexOf(listingId) !== -1
    );
}

const getDateMinusDays = function (days: number): Date {
    const now = new Date();

    return new Date((new Date()).setDate(now.getDate() - days));
}

export const timeChoices = [
    [0, 'Common.Today'],
    [1, 'Common.Yesterday'],
    [2 * 7 + 1, 'Common.TwoWeeksAgo'],
    [4 * 7 + 1, 'Common.FourWeeksAgo'],
    [6 * 7 + 1, 'Common.SixWeeksAgo'],
    [8 * 7 + 1, 'Common.EightWeeksAgo'],
    [365, 'Common.OneYearAgo'],
];

export const filterWordsByTimeChoice = function (timeChoice: number, words: Array<IWord>): Array<IWord> {
    const getFilteredWords = function (timeChoice: number): Array<IWord> {
        const dateDay = getDateMinusDays(timeChoice);

        const from = new Date(dateDay.getTime());
        from.setHours(0);
        from.setMinutes(0)
        from.setSeconds(0);

        const to = new Date(dateDay.getTime());
        to.setHours(23);
        to.setMinutes(59)
        to.setSeconds(59);

        const wordsFiltered: Array<IWord> = [];

        for (const word of words) {
            const refreshedAt = word.refreshedAt === null ? null : new Date(word.refreshedAt);

            if (
                refreshedAt !== null &&
                refreshedAt >= from &&
                refreshedAt <= to
            ) {
                wordsFiltered.push(word);
            }
        }

        return wordsFiltered;
    }

    if (-1 < timeChoice) {
        return getFilteredWords(timeChoice);
    }

    let filteredWords = [];
    const choicesSelectionMap = {
        '-1': 0,
        '-2': 1,
    }

    for (const choice of timeChoices) {
        filteredWords = filteredWords.concat(getFilteredWords(choice[0] + choicesSelectionMap[timeChoice.toString()] as number));
    }

    return filteredWords;
};

export const setRepeatWords = function (words: Array<IWord>, queryType: string, queryValue: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
        let repeatWords = [];

        switch (queryType) {
            case 'listing':
                repeatWords = filterWordsByListing(queryValue, words);

                break;
            case 'rating':
                repeatWords = filterWordsByRating(queryValue as '1' | '2' | '3', words);

                break;

            case 'time':
                repeatWords = filterWordsByTimeChoice(Number(queryValue), words);

                break;

            case 'checked':
                repeatWords = filterWordsByIds(queryValue.split(',').map((wordId: string) => wordId), words)

                break;

            default:
                reject(`Invalid query type given.`);
        }

        repeatWords = repeatWords.filter((word: IWord) => word.polish.length > 0);

        setRepeatWordsAction(repeatWords);

        resolve(undefined);
    })
}

export const firstLanguageBorderColorClass = computed<string>(() => {
    if (repeatState.firstLanguage === "polish") {
        return "w3-border-red";
    }
    if (repeatState.firstLanguage === "english") {
        return "w3-border-blue";
    }

    throw "Invalid language passed!";
});

export const secondLanguageBorderColorClass = computed<string>(() => {
    if (repeatState.firstLanguage === "polish") {
        return "w3-border-blue";
    }
    if (repeatState.firstLanguage === "english") {
        return "w3-border-red";
    }

    throw "Invalid language passed!"
});

export const addNegativePoint = function (currentWordId: string): void {
    if (repeatPointsState.repeatedWords.indexOf(currentWordId) === -1) {
        repeatPointsState.negativePoints++;
    }
}

export const addPoint = function (currentWordId: string): void {
    if (repeatPointsState.repeatedWords.indexOf(currentWordId) === -1) {
        repeatPointsState.points++;
    }
}

export const resetRepeatPointsState = function (): void {
    repeatPointsState.repeatedWords = [];
    repeatPointsState.points = 0;
    repeatPointsState.negativePoints = 0;
}

export const addWordToRepeatedWords = function (currentWordId: string): void {
    if (repeatPointsState.repeatedWords.indexOf(currentWordId) === -1) {
        repeatPointsState.repeatedWords.push(currentWordId);
    }
}

export const setScoreModalType = function (): void {
    if (score.value[0] > 80) {
        repeatPointsState.scoreModalType = ModalType.INFO;

        return;
    }

    if (score.value[0] > 49) {
        repeatPointsState.scoreModalType = ModalType.WARNING;

        return;
    }

    repeatPointsState.scoreModalType = ModalType.ALERT;
}

export const score = computed<Array<number>>(() => {
    const pointsSum = repeatPointsState.points + repeatPointsState.negativePoints;

    if (pointsSum === 0) {
        return [0, 0];
    }

    const onePart = 100 / pointsSum;

    return [
        Math.round(repeatPointsState.points * onePart),
        Math.round(repeatPointsState.negativePoints * onePart),
    ];
});

export const repeatState = reactive<{
    currentWords: Array<IWord>,
    firstLanguage: 'polish' | 'english',
    secondLanguage: string
}>({
    currentWords: [],
    firstLanguage: "polish",
    secondLanguage: "english",
});

export const repeatPointsState = reactive<{
    points: number,
    negativePoints: number,
    repeatedWords: Array<string>,
    scoreModalIsVisible: boolean,
    scoreModalType: ModalType
}>({
    points: 0,
    negativePoints: 0,
    repeatedWords: [],
    scoreModalIsVisible: false,
    scoreModalType: ModalType.INFO,
});

export const currentWord = computed<IWord | null>(() => getCurrentWord());
export const repeatWords = computed<Array<IWord>>(() => getRepeatWords());

export const user = computed<User | null>(() => getUser());

export const ERROR_MESSAGE_ON_SET_REPEAT_WORDS = "Setting of repeatWords to repeat failed!";

export const toLoadDictionariesDataComponent = function (): boolean {
    return currentWord.value &&
        user.value &&
        ['', 'other'].indexOf(user.value.language) === -1
}

export const countFullWordsInListing = function (listing: IListing): number {
    return listing.words.filter(
        (word: IWord) => word.polish.length > 0
    ).length;
}

export const guessLocale = function (): string {
    const localStorageLocale = localStorage.getItem('locale');

    if (['pl', 'en'].indexOf(localStorageLocale) !== -1) {
        return localStorageLocale;
    }

    if (typeof navigator.language !== 'string') {
        return 'en';
    }

    return navigator.language.startsWith('pl') ? 'pl' : 'en';
}