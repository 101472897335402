const shuffleArray = function (a: Array<any>): Array<any> {
    const aLength: number = a.length;
    const b: Array<any> = a.slice();
    let bLength: number = b.length;
    const c: Array<any> = [];
    let cLength: number = c.length;
    while (cLength < aLength) {
        const index: number = Math.floor(Math.random() * bLength);
        c.push(b[index]);
        cLength++;
        b.splice(index, 1);
        bLength--;
    }
    return c;
}

const getRandomNumber = function (min: number, max: number): number {
    return Math.floor((Math.random() * max) + min);
}

const copyObject = function (o: any): any {
    return JSON.parse(JSON.stringify(o));
}

const addProperty = function (o: any, propertyName: string, propertyValue: string) {
    o[propertyName] = propertyValue;

    return o;
}

const objectIsEmpty = function (o: any): boolean {
    for (const prop in o) {
        if (typeof o[prop] !== 'undefined')
            return false;
    }

    return true;
}

const whiteToSpace = function (str: string): string {
    return str.replaceAll(/\s+/g, " ");
}

const removeFromArray = function (a: Array<any>, element: any) {
    let index: number = a.indexOf(element);
    while (index !== -1) {
        a.splice(index, 1);
        index = a.indexOf(element);
    }
    return a;
}

const parseJwt = (token: string) => {
    try {
        const base64Url: string = token.split('.')[1];
        const base64: string = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload: string = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    } catch (e) {
        return null;
    }
};

const isJwtValid = (token: string, id: number): boolean => {
    const parsedJwt = parseJwt(token);

    return parsedJwt !== null && parsedJwt.user_id === id && parsedJwt.exp * 1000 > Date.now();
}

const cleanPhrase = function (phrase: string): string {
    return phrase
        .toLowerCase()
        .replaceAll(/\s/gi, "")
        .replaceAll(/[.'"-\\/[](){}`~!?@#\$%\^&*_+-=|:<>]/gi, "")
        .replaceAll(/kogoś/gi, "")
        .replaceAll(/kogos/gi, "")
        .replaceAll(/czegoś/gi, "")
        .replaceAll(/czegos/gi, "")
        .replaceAll(/coś/gi, "")
        .replaceAll(/cos/gi, "")
        .replaceAll(/someone/gi, "")
        .replaceAll(/one's/gi, "")
        .replaceAll(/somewhere/gi, "")
        .replaceAll(/somebody/gi, "")
        .replaceAll(/something/gi, "");
};

const phrasesMatch = function (one: string, two: string) {
    const cleanedOne = cleanPhrase(one).replaceAll(/\(.{0,}\)/gi, '');
    const cleanedTwo = cleanPhrase(two).replaceAll(/\(.{0,}\)/gi, '');

    if (cleanedOne.replaceAll(/[,;]/gi, "") === cleanedTwo.replaceAll(/[,;]/gi, "")) {
        return true;
    }

    const arrayFromOne = [];

    for (const phraseSlicesByComma of cleanedOne.split(",")) {
        for (const phraseSlicesBySemicolon of phraseSlicesByComma.split(";")) {
            arrayFromOne.push(phraseSlicesBySemicolon);
        }
    }

    for (const phrase of arrayFromOne) {
        if (phrase === cleanedTwo) {
            return true;
        }
    }

    const arrayFromTwo = [];

    for (const phraseSlicesByComma of cleanedTwo.split(",")) {
        for (const phraseSlicesBySemicolon of phraseSlicesByComma.split(";")) {
            arrayFromTwo.push(phraseSlicesBySemicolon);
        }
    }

    for (const phrase of arrayFromTwo) {
        if (phrase === cleanedOne) {
            return true;
        }
    }

    return false;
};

export { cleanPhrase, phrasesMatch, shuffleArray, getRandomNumber, copyObject, addProperty, objectIsEmpty, whiteToSpace, removeFromArray, isJwtValid };