import Vuex from 'vuex'
import auth from './modules/auth';
import repeat from './modules/repeat';
import modals from './modules/modals';

const store: any = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    repeat,
    modals,
  },
  strict: process.env.NODE_ENV !== 'production',
});

export default store;