import { User } from "../definitions/auth";
import store from '@/store/index';
import Cookies from "js-cookie";
import { isJwtValid } from "@/lib/common/utils";

const COOKIE_AUTH_TOKEN = 'authToken';

export const getUser = (): User | null => {
    return store.state.auth.user;
}

export const isAuthenticated = function (): boolean {
    const authToken = getAuthToken();
    const user = getUser();

    return authToken && user && isJwtValid(authToken, user.id);
}

export const getRequestToken = (): string => `Bearer ${getAuthToken()}`;

export const removeOldStore = function (): void {
    Cookies.remove('vuex');
}

export const resetMainState = function (): Promise<undefined> {
    return new Promise((resolve) => {
        store.dispatch('resetMainState').then(() => {
            resolve(undefined);
        })
    })
}

export const setAuthToken = function (authToken: string): Promise<undefined> {
    return new Promise((resolve) => {
        Cookies.set(COOKIE_AUTH_TOKEN, authToken, { secure: true, sameSite: 'Strict', expires: 1 });
        resolve(undefined);
    });
}

export const removeAuthToken = function (): Promise<undefined> {
    return new Promise((resolve) => {
        Cookies.remove(COOKIE_AUTH_TOKEN);
        resolve(undefined);
    });
}

export const getAuthToken = function (): string | undefined {
    return Cookies.get(COOKIE_AUTH_TOKEN);
}