import { createApp } from 'vue';
import App from './App.vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import router from './router'
import store from './store/index'
import { createI18n } from "vue-i18n"
import './css/w3.css'
import 'animate.css'
import './css/animate-custom.css'
import './css/material_fonts.css'
import './css/fonts.css'
import './css/x.css'
import './css/flex.css'
import './css/base.css'
import './css/base_desktop.css'
import './css/base_mobile.css'

import pl from './locale/pl.json'
import en from './locale/en.json'
import { guessLocale } from './lib/repeat/utils';

const i18n = createI18n({
    locale: guessLocale(),
    fallbackLocale: "en",
    messages: { pl, en },
    legacy: false,
});

const app = createApp(App);

app
    .use(router)
    .use(store)
    .use(i18n)
    .use(VueReCaptcha, {
        siteKey: '6LfUt6IUAAAAAOj3HNv46EjujVxoKOhrUoiSGpvq', loaderOptions: {
            useRecaptchaNet: null,
            useEnterprise: null,
            autoHideBadge: null,
            renderParameters: null
        }
    })
    .mount('#app')

app.config.globalProperties.$store = store;
