import store from '../index';
import { createAuthState, IAuthState, IUserData, User } from "../definitions/auth";
import { removeAuthToken } from '../actions/auth';

const state: IAuthState = createAuthState();

const mutations = {
    resetAuthState: (state: IAuthState): void => {
        Object.assign(state, createAuthState())
    },
    setUser: (state: IAuthState, userData: IUserData) => {
        state.user = new User(
            userData.id,
            userData.username,
            userData.isSuperuser,
            userData.language,
            userData.loginsCount,
            userData.allWordsCount,
            userData.fullWordsCount
        );
    },
    increaseUserWordsNumber: (state: IAuthState, isFullWord: boolean) => {
        state.user.allWordsCount++;

        if (isFullWord) {
            state.user.fullWordsCount++;
        }
    },
    decreaseUserWordsNumber: (state: IAuthState) => {
        state.user.allWordsCount--;
        state.user.fullWordsCount--;
    },
}

const actions = {
    resetMainState: ({ commit }): Promise<undefined> => {
        return new Promise((resolve) => {
            removeAuthToken()
                .then(() => {
                    commit('resetModalsState');
                    commit('resetRepeatState');
                    commit('resetAuthState');

                    resolve(undefined);
                });
        });
    },
    authLogout: () => {
        return new Promise(resolve => {
            store.dispatch('resetMainState')
                .then(() => {
                    resolve(undefined);
                });
        })
    },
}

export default {
    state,
    actions,
    mutations
};