export enum RepeatTypes {
    READ = 'read',
    WRITE = 'write',
    CHOOSE = 'choose',
    LISTING = 'listing',
}

export enum QueryTypes {
    CHECKED = 'checked',
    LISTING = 'listing',
    RATING = 'rating',
    TIME = 'time'
}