
import store from '@/store/index';
import { ModalType, IModalsState } from '@/store/definitions/modals';

export const showModalSimpleError = function (text: string = ':-('): void {
    store.commit('showModalSimple', {
        type: ModalType.ERROR, text: text, isVisible: true
    });
}

export const showModalSimpleSuccess = function (text: string): void {
    store.commit('showModalSimple', {
        type: ModalType.SUCCESS, text: text, isVisible: true
    });
}

export const showModalSimpleInfo = function (text: string): void {
    store.commit('showModalSimple', {
        type: ModalType.INFO, text: text, isVisible: true
    });
}

export const showModalSimpleWarning = function (text: string): void {
    store.commit('showModalSimple', {
        type: ModalType.WARNING, text: text, isVisible: true
    });
}

export const showModalLoader = function (): void {
    store.commit('showModalLoader');
}

export const hideModalLoader = function (): void {
    store.commit('hideModalLoader');
}

export const hideModalSimple = function (): void {
    store.commit('hideModalSimple');
}

export const showModalBoxSearch = function (): void {
    store.commit('showModalBoxSearch');
}

export const hideModalBoxSearch = function (): void {
    store.commit('hideModalBoxSearch');
}

export const isModalBoxSearchVisible = function (): boolean {
    return store.state.modals.modalBoxSearchIsVisible;
}

export const getModalsState = function (): IModalsState {
    return store.state.modals;
}

export const clearFlashes = function (): void {
    store.commit('clearFlashes');
}

export const removeFlash = function (index: number): void {
    store.commit('removeFlash', index);
}

export const flashInfo = function (message: string): void {
    store.commit('addFlash', { type: ModalType.INFO, message: message });
}

export const flashSuccess = function (message: string): void {
    store.commit('addFlash', { type: ModalType.SUCCESS, message: message });
}

export const flashWarning = function (message: string): void {
    store.commit('addFlash', { type: ModalType.WARNING, message: message });
}

export const flashAlert = function (message: string): void {
    store.commit('addFlash', { type: ModalType.ALERT, message: message });
}

export const flashError = function (message: string): void {
    store.commit('addFlash', { type: ModalType.ERROR, message: message });
}