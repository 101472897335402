<template>
    <section class="x-flex-row x-flex-text-center welcome-authentication-buttons">
        <router-link to="/login" class="w3-button w3-blue w3-animate-left w3-xlarge">{{ t('WelcomeView.SignIn')
        }}</router-link>
        <router-link to="/signup" class="w3-button w3-orange w3-animate-right w3-xlarge">{{ t('WelcomeView.SignUp')
        }}</router-link>
    </section>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>