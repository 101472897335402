import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w3-modal modal-simple not-hide-menus-on-click" }
const _hoisted_2 = { class: "w3-modal-content w3-card-2" }
const _hoisted_3 = { class: "x-padding-medium w3-light-grey not-hide-menus-on-click" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    "enter-active-class": "w3-animate-opacity",
    "leave-active-class": "animate__animated animate__fadeOut"
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("section", _hoisted_1, [
        _createVNode(_Transition, {
          "enter-active-class": "animate__animated animate__fadeInDown",
          "leave-active-class": "animate__animated animate__fadeOutUp"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_2, [
              _createElementVNode("header", {
                class: _normalizeClass(["x-padding-medium b-modal-header not-hide-menus-on-click", [_ctx.colorClass]])
              }, _toDisplayString(_ctx.modal.text), 3),
              _createElementVNode("footer", _hoisted_3, [
                _createElementVNode("button", {
                  class: _normalizeClass(["w3-btn w3-hover-white not-hide-menus-on-click", [_ctx.colorClass]]),
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hideModalSimple && _ctx.hideModalSimple(...args)))
                }, " OK ", 2)
              ])
            ], 512), [
              [_vShow, _ctx.modal.isVisible]
            ])
          ]),
          _: 1
        })
      ], 512), [
        [_vShow, _ctx.modal.isVisible]
      ])
    ]),
    _: 1
  }))
}