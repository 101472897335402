
import { defineComponent } from "vue";
import IconButton from "./IconButton.vue";
import WordBox from "@/components/elements/WordBox.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: { IconButton, WordBox },
  props: {
    word: {
      type: Object,
      required: true,
    },
    showRepeatCheckbox: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    const { t } = useI18n();

    const checkWord = (event) => {
      context.emit("checkWord", event);
    };

    return {
      t,
      checkWord,
    };
  },
});
