
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    checked: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const myChange = (event) => {
      context.emit("myChange", event);
    };

    return {
      myChange,
    };
  },
});
