export const SHOW_MODAL_LISTINGS = "SHOW_MODAL_LISTINGS";
export const HIDE_MODAL_LISTINGS = "HIDE_MODAL_LISTINGS";
export const SHOW_REPEAT_MENU_BUTTON = "SHOW_REPEAT_MENU_BUTTON";
export const HIDE_REPEAT_MENU_BUTTON = "HIDE_REPEAT_MENU_BUTTON";
export const SET_ADDITIONAL_WORD_DATA_SECTION_CAN_BE_SHOWED = "SET_ADDITIONAL_WORD_DATA_SECTION_CAN_BE_SHOWED";
export const SET_ADDITIONAL_WORD_DATA_SECTION_CANNOT_BE_SHOWED = "SET_ADDITIONAL_WORD_DATA_SECTION_CANNOT_BE_SHOWED";

import store from '@/store/index';
import { ExternalWordsCollection, IListing, IWord } from '../definitions/repeat';

export function setCurrentWord(word: IWord | null): void {
    store.commit('setCurrentWord', word)
}

export function addListing(listing: IListing): void {
    store.commit('addListing', listing)
}

export function deleteListing(listing: IListing): void {
    store.commit('deleteListing', listing)
}

export function updateWordInRepeatWords(word: IWord): void {
    store.commit('updateWordInRepeatWords', word)
}

export function updateListingInListings(listing: IListing): void {
    store.commit('updateListingInListings', listing)
}

export function setWords(words: Array<IWord>): void {
    store.commit('setWords', words)
}

export function setRepeatWords(repeatWords: Array<IWord>): void {
    store.commit('setRepeatWords', repeatWords)
}

export function setListings(listings: Array<IListing>): void {
    store.commit('setListings', listings)
}

export function getCurrentWord(): IWord | null {
    return store.state.repeat.currentWord;
}

export function getWords(): Array<IWord> {
    return store.state.repeat.words;
}

export function getRepeatWords(): Array<IWord> {
    return store.state.repeat.repeatWords;
}

export function getListings(): Array<IListing> {
    return store.state.repeat.listings;
}

export function toggleCurrentWordExistenceInRepeatWords(word: IWord, action: boolean): void {
    store.commit('toggleCurrentWordExistenceInRepeatWords', { word: word, action: action })
}

export function toggleWordExistenceInWords(word: IWord, action: boolean): void {
    store.commit('toggleWordExistenceInWords', { word: word, action: action })

    toggleCurrentWordExistenceInRepeatWords(word, action);
}

export function updateWordInWords(word: IWord): void {
    store.commit('updateWordInWords', word)

    updateWordInRepeatWords(word)
}

export function addWordsToDictionariesWordsCollection(dictionariesWords: ExternalWordsCollection): void {
    store.commit('addWordsToDictionariesWordsCollection', {
        wordId: dictionariesWords.phrase,
        dictionariesWords: (dictionariesWords.words.length === 0 ? null : dictionariesWords)
    });
}

export function getWordsFromDictionariesWordsCollection(wordId: string): ExternalWordsCollection | null | undefined {
    return typeof store.state.repeat.dictionariesWordsCollection[wordId] === 'undefined' ? undefined : store.state.repeat.dictionariesWordsCollection[wordId];
}