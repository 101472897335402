<template>
    <header class="
          main-header
          w3-text-dark-grey
          animate__animated animate__fadeInDown
          x-flex-column x-margin-large
        ">
        <h1 class="w3-xxlarge w3-center">twojeslowka.online</h1>
        <h3 class="w3-large w3-center">
            {{ t('WelcomeView.EnglishGermanSpanish') }}
        </h3>
    </header>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>