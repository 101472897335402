
import { hideModalBoxSearch } from "@/store/actions/modals";
import { computed, defineComponent, watch } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const close = (e): void => {
      context.emit("close", e);
    };

    const modalBoxIsVisible = computed<boolean>(() => {
      return props.isVisible;
    });

    const route = useRoute();

    watch(
      () => {
        return route.path;
      },
      () => {
        hideModalBoxSearch();
      }
    );

    return { modalBoxIsVisible, close };
  },
});
