
import { defineComponent, ref, computed } from "vue";
import { addProperty } from "../../lib/common/utils";
import {
  showModalSimpleError,
  showModalSimpleInfo,
  showModalLoader,
  hideModalLoader,
} from "@/store/actions/modals";
import { required, minLength, maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useReCaptcha } from "vue-recaptcha-v3";
import { post } from "@/lib/common/requests";
import BlockContainer from "@/components/elements/BlockContainer.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: { BlockContainer },
  name: "ContactView",
  setup: function () {

    const { t } = useI18n()

    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    interface IForm {
      name: string;
      content: string;
    }

    const getEmptyForm = function (): IForm {
      return {
        name: "",
        content: "",
      };
    };

    let form = ref<IForm>(getEmptyForm());

    const validations: any = computed(function () {
      return {
        form: {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(100),
          },
          content: {
            required,
            minLength: minLength(4),
            maxLength: maxLength(1000),
          },
        },
      };
    });

    const validator: any = useVuelidate(validations, { form });

    const getRecaptchaToken = async function (): Promise<string> {
      await recaptchaLoaded();
      const token: string = await executeRecaptcha("ContactView");
      return token;
    };

    const send = function (): void {
      validator.value.$touch();

      if (
        validator.value.$error
      ) {
        return;
      }

      showModalLoader();

      getRecaptchaToken().then((recaptchaToken: string) => {
        post(
          "/api/administration/contact/",
          addProperty(form.value, "recaptchaToken", recaptchaToken),
          false
        )
          .then((resp) => {
            if (resp.status !== 204) {
              throw Error(resp.status.toString());
            }

            hideModalLoader();
            showModalSimpleInfo(t('ContactView.MessageSent') + " :-)");

            form.value = getEmptyForm();

            validator.value.$reset();
          })
          .catch(() => {
            hideModalLoader();
            showModalSimpleError();
          });
      });
    };

    return {
      t,
      getEmptyForm,
      form,
      send,
      validator,
    };
  },
});
