export interface IId {
    readonly id: string;
}

export default {
    add: function (item: IId, resource: Array<IId>, toBeginning: boolean = false): void {
        if (toBeginning) {
            resource.unshift(item);
        } else {
            resource.push(item);
        }
    },
    put: function (item: IId, resource: Array<IId>, errorIfNotFound: boolean = false): void {
        const resourceLength = resource.length;

        for (let i = 0; i < resourceLength; i++) {
            if (resource[i].id === item.id) {
                resource[i] = Object.assign(resource[i], item);

                return;
            }
        }

        if (errorIfNotFound) {
            throw Error(`Item with ID ${item.id} not exists.`);
        }
    },
    delete: function (item: IId, resource: Array<IId>, errorIfNotFound: boolean = false): void {
        const resourceLength = resource.length;

        for (let i = 0; i < resourceLength; i++) {
            if (resource[i].id === item.id) {
                resource.splice(i, 1);

                return;
            }
        }

        if (errorIfNotFound) {
            throw Error(`Item with ID ${item.id} not exists.`);
        }
    },
    get: function (id: string, resource: Array<IId>): IId | null {
        const resourceLength = resource.length;

        for (let i = 0; i < resourceLength; i++) {
            if (resource[i].id === id) {
                return resource[i];
            }
        }

        return null;
    },
    filter: function (ids: Array<string>, resource: Array<IId>): Array<IId> {
        return resource.filter((item: IId) => ids.indexOf(item.id) !== -1);
    }
}