import {
    SHOW_MODAL_LISTINGS,
    HIDE_MODAL_LISTINGS,
    SHOW_REPEAT_MENU_BUTTON,
    HIDE_REPEAT_MENU_BUTTON,
    SET_ADDITIONAL_WORD_DATA_SECTION_CAN_BE_SHOWED,
    SET_ADDITIONAL_WORD_DATA_SECTION_CANNOT_BE_SHOWED,
} from "../actions/repeat";
import collection from '../../lib/repeat/collection';
import { createWordId } from '../../lib/repeat/utils';
import { getUser } from "../actions/auth";
import { Language } from "../definitions/auth";
import { IRepeatState, IListing, IWord, createRepeatState, ExternalWordsCollection } from "../definitions/repeat";

const state: IRepeatState = createRepeatState();

const mutations = {
    setWords: (state: IRepeatState, words: Array<IWord>) => {
        state.words = words;
    },
    setRepeatWords: (state: IRepeatState, repeatWords: Array<IWord>) => {
        state.repeatWords = repeatWords;
    },
    setListings: (state: IRepeatState, listings: Array<IListing>) => {
        state.listings = listings;
    },
    setCurrentWord: (state: IRepeatState, currentWord: IWord | null) => {
        if (currentWord) {
            currentWord.wordId = createWordId(currentWord.english, [Language.En, Language.EnUs, Language.EnGb].indexOf(getUser().language) !== -1)
        }

        state.currentWord = currentWord;
    },
    addWordsToDictionariesWordsCollection: (state: IRepeatState, data: { wordId: string, dictionariesWords: ExternalWordsCollection | null }) => {
        state.dictionariesWordsCollection[data.wordId] = data.dictionariesWords;
    },
    updateListingInListings: (state: IRepeatState, listing: IListing) => {
        collection.put(listing, state.listings);
    },
    updateWordInRepeatWords: (state: IRepeatState, word: IWord) => {
        collection.put(word, state.repeatWords);
    },
    deleteListing: (state: IRepeatState, listing: IListing) => {
        collection.delete(listing, state.listings);
    },
    addListing: (state: IRepeatState, listing: IListing) => {
        collection.add(listing, state.listings, false);
    },
    [SHOW_MODAL_LISTINGS]: (state) => {
        state.modalListingsIsVisible = true;
    },
    [HIDE_MODAL_LISTINGS]: (state) => {
        state.modalListingsIsVisible = false;
    },
    [SHOW_REPEAT_MENU_BUTTON]: (state) => {
        state.repeatMenuButtonIsVisible = true;
    },
    [HIDE_REPEAT_MENU_BUTTON]: (state) => {
        state.repeatMenuButtonIsVisible = false;
    },
    [SET_ADDITIONAL_WORD_DATA_SECTION_CAN_BE_SHOWED]: (state) => {
        state.additionalWordDataSectionCanBeShowed = true;
    },
    [SET_ADDITIONAL_WORD_DATA_SECTION_CANNOT_BE_SHOWED]: (state) => {
        state.additionalWordDataSectionCanBeShowed = false;
    },
    toggleCurrentWordExistenceInRepeatWords: (state: IRepeatState, data: { word: IWord, action: boolean }) => {
        if (data.action) {
            collection.add(data.word, state.repeatWords);
        } else {
            collection.delete(data.word, state.repeatWords);
        }
    },
    toggleWordExistenceInWords: (state: IRepeatState, data: { word: IWord, action: boolean }) => {
        if (data.action) {
            collection.add(data.word, state.words, false);
        } else {
            collection.delete(data.word, state.words, true);
        }
    },
    updateWordInWords: (state: IRepeatState, word: IWord) => {
        collection.put(word, state.words, true);
    },
    resetRepeatState: (state: IRepeatState): void => {
        Object.assign(state, createRepeatState());
    }
}

export default {
    state,
    mutations
};