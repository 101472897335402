import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["w3-button w3-medium round-button", {
      'round-button-size': !_ctx.isLarger,
      'round-button-size-larger': _ctx.isLarger,
    }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.myClick && _ctx.myClick(...args)))
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(["material-icons", {
        'round-button-icon-size': !_ctx.isLarger,
        'round-button-icon-size-larger': _ctx.isLarger,
      }])
    }, _toDisplayString(_ctx.icon), 3)
  ], 2))
}