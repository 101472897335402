<template>
  <div class="flex-element">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
</script>

<style scoped>
.flex-element {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
</style>