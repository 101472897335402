export enum ModalType {
    INFO,
    SUCCESS,
    CONFIRM,
    WARNING,
    ERROR,
    ALERT,
}

export interface IModal {
    type: ModalType;
    text: string;
    isVisible: boolean;
}

export interface IFlash {
    type: ModalType;
    message: string;
}

export interface IModalsState {
    modalLoaderIsVisible: boolean,
    modalSimple: IModal,
    modalBoxSearchIsVisible: boolean,
    flashes: Array<IFlash>,
}

export const createModalsState = function (): IModalsState {
    return {
        modalLoaderIsVisible: false,
        modalSimple: {
            type: ModalType.INFO,
            text: '',
            isVisible: false
        },
        modalBoxSearchIsVisible: false,
        flashes: [],
    }
}

export const getModalColorClass = function (modalType: ModalType): string {
    switch (modalType) {
        case ModalType.INFO:
            return "w3-blue";
        case ModalType.SUCCESS:
            return "w3-teal";
        case ModalType.CONFIRM:
            return "w3-pink";
        case ModalType.WARNING:
            return "w3-orange";
        case ModalType.ERROR:
        case ModalType.ALERT:
            return "w3-red";
    }
};