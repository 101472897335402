import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ab4449e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w3-animate-left clickable-element" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_round_button = _resolveComponent("round-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_round_button, {
      icon: _ctx.icon,
      isLarger: _ctx.isLarger,
      title: _ctx.title,
      onClick: _ctx.myClick
    }, null, 8, ["icon", "isLarger", "title", "onClick"]),
    _createElementVNode("div", {
      class: _normalizeClass(["w3-border-bottom", [_ctx.borderColorClass, { 'text-uppercased': _ctx.textUppercased }]])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ]))
}