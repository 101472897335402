
import { defineComponent } from "vue";
import Information from "../../components/Information.vue";

export default defineComponent({
  name: "PageNotFound404View",
  components: {
    Information,
  },
  setup() {},
});
