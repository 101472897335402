
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true,
    },
    isLarger: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    const myClick = (event) => {
      context.emit("myClick", event);
    };

    return {
      myClick,
    };
  },
});
