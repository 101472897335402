import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a066acf8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "word-list-row" }
const _hoisted_2 = { class: "word-list-row-words" }
const _hoisted_3 = { class: "word-list-row-buttons" }
const _hoisted_4 = ["value", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_word_box = _resolveComponent("word-box")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_word_box, {
        borderColorClass: "w3-border-red",
        class: _normalizeClass(["w3-animate-left", { 'mobile-display-none': _ctx.word.polish.length === 0 }])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.word.polish), 1)
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_word_box, {
        borderColorClass: "w3-border-blue",
        class: "w3-animate-right"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.word.english), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("input", {
        class: _normalizeClass(["w3-check w3-margin", { 'x-hidden': _ctx.word.polish.length === 0 }]),
        type: "checkbox",
        value: _ctx.word.id,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.checkWord && _ctx.checkWord(...args))),
        title: _ctx.t('WordListRow.AddToReview')
      }, null, 42, _hoisted_4), [
        [_vShow, _ctx.showRepeatCheckbox]
      ]),
      _createVNode(_component_router_link, {
        to: `/word/show/${_ctx.word.id}`,
        title: _ctx.t('Common.ShowWord')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_icon_button, { icon: "visibility" })
        ]),
        _: 1
      }, 8, ["to", "title"]),
      _createVNode(_component_router_link, {
        to: `/word/edit/${_ctx.word.id}`,
        title: _ctx.t('Common.EditWord')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_icon_button, { icon: "edit" })
        ]),
        _: 1
      }, 8, ["to", "title"])
    ])
  ]))
}