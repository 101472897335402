import { IId } from "@/lib/repeat/collection";

export class Sense {
    category: string | null;
    definition: string | null;
    examples: Array<string>;

    constructor(
        category: LexicalCategory | null,
        definition: string | null,
        examples: Array<string>
    ) {
        this.category = category;
        this.definition = definition;
        this.examples = examples;
    }
}

export class Pronunciation {
    spelling: string | null;
    audio: string | null;

    constructor(
        spelling: string | null,
        audio: string | null,
    ) {
        this.spelling = spelling;
        this.audio = audio;
    }
}

export class ExternalWord {
    id: string;
    pronunciations: Array<Pronunciation>;
    senses: Array<Sense>;

    constructor(
        id: string,
        pronunciations: Array<Pronunciation>,
        senses: Array<Sense>
    ) {
        this.id = id;
        this.pronunciations = pronunciations;
        this.senses = senses;
    }
}

export class ExternalWordsCollection {
    phrase: string;
    words: Array<ExternalWord>;

    constructor(phrase: string, words: Array<ExternalWord>) {
        this.phrase = phrase;
        this.words = words;
    }
}

export enum LexicalCategory {
    Undefined = '',
    Verb = 'Czasownik',
    Noun = 'Rzeczownik',
    Adjective = 'Przymiotnik',
    Adverb = 'Przysłówek',
    Conjunction = 'Spójnik',
    Idiom = 'Idiom',
    Numeral = 'Liczebnik',
    Preposition = 'Przyimek',
    Pronoun = 'Zaimek'
}

export interface IWord extends IId {
    polish: string;
    english: string;
    sentence: string;
    listingIds: Array<string>;
    lexicalCategory: LexicalCategory | null;
    rating: "1" | "2" | "3";
    wordId?: string | null | undefined;
    readonly createdAt: string;
    refreshedAt: string | null;
}

export interface IListing extends IId {
    name: string;
    words: Array<IWord>;
    fullWordsCount: number;
    readonly createdAt: string
}

export interface IWordOutput {
    polish: string;
    english: string;
    sentence: string;
    listingIds: Array<string>;
    lexicalCategory: LexicalCategory;
    rating: "1" | "2" | "3";
}

export interface IRepeatState {
    words: Array<IWord>;
    repeatWords: Array<IWord>;
    currentWord: IWord | null;
    listings: Array<IListing>;
    modalListingsIsVisible: boolean;
    repeatMenuButtonIsVisible: boolean;
    additionalWordDataSectionCanBeShowed: boolean;
    dictionariesWordsCollection: any;
}

export const createRepeatState = function (): IRepeatState {
    return {
        words: [],
        repeatWords: [],
        currentWord: null,
        listings: [],
        modalListingsIsVisible: false,
        repeatMenuButtonIsVisible: false,
        additionalWordDataSectionCanBeShowed: false,
        dictionariesWordsCollection: {},
    }
}