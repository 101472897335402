import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4528a5ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-row flex-align-items-center my-radio" }
const _hoisted_2 = ["name", "value", "id", "checked", "disabled"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("input", {
      type: "radio",
      name: _ctx.name,
      value: _ctx.value,
      class: "w3-radio",
      id: _ctx.id,
      checked: _ctx.checked,
      disabled: _ctx.disabled,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.myChange && _ctx.myChange(...args)))
    }, null, 40, _hoisted_2),
    _createElementVNode("label", {
      for: _ctx.id,
      class: _normalizeClass(["w3-validate w3-button w3-border my-border-grey w3-tiny", {
        'my-grey': _ctx.checked,
        'w3-light-grey': !_ctx.checked,
      }])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 10, _hoisted_3)
  ]))
}