import { getRequestToken } from "@/store/actions/auth";
import { guessLocale } from "../repeat/utils";

const getHeaders = (authenticate: boolean = true): Headers => {
    const headers = new Headers({
        "X-Requested-With": "XMLHttpRequest",
        "Accept-Language": guessLocale(),
    });

    if (authenticate) {
        headers.append('Authorization', getRequestToken())
    }

    return headers;
}

const getContentTypeJsonHeaders = (authenticate: boolean = true): Headers => {
    const headers = getHeaders(authenticate);

    headers.append('Content-Type', 'application/json')

    return headers;
}

const backendAppUrl: string = `${process.env.VUE_APP_BACKEND_APP_URL}`;

export const get = async (url: string, authenticate: boolean = true) => {
    return await fetch(`${backendAppUrl}${url}`, {
        method: "GET",
        headers: getHeaders(authenticate),
    })
}

export const post = async (url: string, body: any = {}, authenticate: boolean = true) => {
    return await fetch(`${backendAppUrl}${url}`, {
        method: "POST",
        headers: getContentTypeJsonHeaders(authenticate),
        body: JSON.stringify(body)
    })
}

export const remove = async (url: string, authenticate: boolean = true) => {
    return await fetch(`${backendAppUrl}${url}`, {
        method: "DELETE",
        headers: getHeaders(authenticate),
    })
}

export const patch = async (url: string, body: any = {}, authenticate: boolean = true) => {
    return await fetch(`${backendAppUrl}${url}`, {
        method: "PATCH",
        headers: getContentTypeJsonHeaders(authenticate),
        body: JSON.stringify(body)
    })
}

export const put = async (url: string, body: any = {}, authenticate: boolean = true) => {
    return await fetch(`${backendAppUrl}${url}`, {
        method: "PUT",
        headers: getContentTypeJsonHeaders(authenticate),
        body: JSON.stringify(body)
    })
}