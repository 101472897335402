import { IModalsState, IModal, createModalsState, IFlash, ModalType } from '@/store/definitions/modals';

const state = createModalsState();

const mutations = {
    showModalLoader(state: IModalsState): void {
        state.modalLoaderIsVisible = true;
    },
    hideModalLoader(state: IModalsState): void {
        state.modalLoaderIsVisible = false;
    },
    showModalSimple(state: IModalsState, modal: IModal): void {
        state.modalSimple = modal
    },
    hideModalSimple(state: IModalsState): void {
        state.modalSimple.isVisible = false;
    },
    showModalBoxSearch(state: IModalsState): void {
        state.modalBoxSearchIsVisible = true;
    },
    hideModalBoxSearch(state: IModalsState): void {
        state.modalBoxSearchIsVisible = false;
    },
    addFlash: (state: IModalsState, flash: IFlash) => {
        state.flashes.push(flash);
    },
    clearFlashes: (state: IModalsState) => {
        state.flashes = [];
    },
    removeFlash: (state: IModalsState, index: number) => {
        state.flashes.splice(index, 1);
    },
    resetModalsState: (state: IModalsState): void => {
        Object.assign(state, {
            modalLoaderIsVisible: false,
            modalSimple: {
                type: ModalType.INFO,
                text: '',
                isVisible: false
            },
            modalBoxSearchIsVisible: false,
        });
    }
}

export default { state, mutations }