import { createRouter, createWebHashHistory } from 'vue-router';
import store from '../store/index';
import RepeatSettings from '../views/RepeatSettings.vue'
import RepeatView from '../views/RepeatView.vue'
import WelcomeView from '../views/WelcomeView.vue'
import LoginView from '../views/auth/LoginView.vue'
import SignUpView from '../views/auth/SignUpView.vue'
import SignUpConfirm from '../views/auth/SignUpConfirm.vue'
import PasswordChangeView from '../views/auth/PasswordChangeView.vue'
import PasswordResetView from '../views/auth/PasswordResetView.vue'
import PasswordResetConfirm from '../views/auth/PasswordResetConfirm.vue'
import WordListView from '../views/WordListView.vue'
import WordEditView from '../views/WordEditView.vue'
import WordAddView from '../views/WordAddView.vue'
import WordShowView from '../views/WordShowView.vue'
import AboutView from '../views/AboutView.vue'
import TermsView from '../views/TermsView.vue';
import ContactView from '../views/administration/ContactView.vue';
import PageNotFound404View from '../views/administration/PageNotFound404View.vue';
import FirstLoginView from '../views/FirstLoginView.vue';
import { getUser, isAuthenticated } from '@/store/actions/auth';

const authenticated = (to, from, next) => {
  if (isAuthenticated()) {
    next();

    return;
  }
  store.dispatch('authLogout').then(() => {
    next('/login');
  });
}

const notAuthenticated = (to, from, next) => {
  if (!isAuthenticated()) {
    store.commit('resetModalsState');
    next();

    return;
  }

  store.dispatch('authLogout').then(() => {
    next('/');
  });
}

const authenticatedAndRequiredWords = (to, from, next) => {
  if (!isAuthenticated()) {
    store.dispatch('authLogout').then(() => {
      next('/login');
    });
  } else if (getUser().allWordsCount > 0) {
    next();
  } else {
    next('/word/add');
  }
}

const authenticatedAndRequiredFullWords = (to, from, next) => {
  if (!isAuthenticated()) {
    store.dispatch('authLogout').then(() => {
      next('/login');
    });
  } else if (getUser().fullWordsCount > 0) {
    next();
  } else {
    next('/word/add');
  }
}

const routes = [
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
    beforeEnter: notAuthenticated,
  },
  {
    path: '/signup',
    name: 'SignUpView',
    component: SignUpView,
    beforeEnter: notAuthenticated,
  },
  {
    path: '/password/change',
    name: 'PasswordChangeView',
    component: PasswordChangeView,
    beforeEnter: authenticated,
  },
  {
    path: '/password/reset',
    name: 'PasswordResetView',
    component: PasswordResetView,
    beforeEnter: notAuthenticated,
  },
  {
    path: '/password/reset/confirm/:uidb64/:token',
    name: 'PasswordResetConfirm',
    component: PasswordResetConfirm,
    beforeEnter: notAuthenticated,
  },
  {
    path: '/signup/confirm/:uidb64/:token',
    name: 'SignUpConfirm',
    component: SignUpConfirm,
    beforeEnter: notAuthenticated,
  },
  {
    path: '/',
    name: 'WelcomeView',
    component: WelcomeView,
    beforeEnter: notAuthenticated,
  },
  {
    path: '/about',
    name: 'AboutView',
    component: AboutView,
  },
  {
    path: '/terms',
    name: 'TermsView',
    component: TermsView,
  },
  {
    path: '/contact',
    name: 'ContactView',
    component: ContactView,
  },
  {
    path: '/repeat/settings',
    name: 'RepeatSettings',
    component: RepeatSettings,
    beforeEnter: authenticatedAndRequiredFullWords,
  },
  {
    path: '/repeat/:repeatType/:queryType/:queryValue',
    name: 'RepeatView',
    component: RepeatView,
    beforeEnter: authenticatedAndRequiredFullWords,
  },
  {
    path: '/wordlist',
    name: 'WordListView',
    component: WordListView,
    beforeEnter: authenticatedAndRequiredWords,
  },
  {
    path: '/word/add',
    name: 'WordAddView',
    component: WordAddView,
    beforeEnter: authenticated,
  },
  {
    path: '/first-login',
    name: 'FirstLoginView',
    component: FirstLoginView,
    beforeEnter: authenticated,
  },
  {
    path: '/word/edit/:id',
    name: 'WordEditView',
    component: WordEditView,
    beforeEnter: authenticatedAndRequiredWords,
  },
  {
    path: '/word/show/:id',
    name: 'WordShowView',
    component: WordShowView,
    beforeEnter: authenticatedAndRequiredWords,
  },
  {
    path: "/not-found",
    name: 'PageNotFound404View',
    component: PageNotFound404View
  },
  {
    path: "/:catchAll(.*)",
    name: 'PageNotFound404View',
    component: PageNotFound404View
  },
]

const scrollBehavior = function (): any {
  return { top: 0 }
}

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,

  scrollBehavior
})

export default router
